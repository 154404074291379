import React from 'react';
import { Link } from 'gatsby';

import Grid from '../../../Grid';
import IconSuccess from '../../../../images/icon-action-success.png';

const BookingSuccess = ({ tour }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="booking-success">
      <Grid>
        <Grid.Col md="2 / span 10" xs="1 / span 2">
          <img src={IconSuccess} alt="Icon Success" role="presentation" />
          <h1>Thank you for choosing to book with us!</h1>
          <p>We appreciate your reservation and will promptly contact you to confirm your booking.</p>

          <div
            style={{
              maxWidth: '345px',
              margin: '32px auto 0 auto',
            }}
          >
            <Link to={`/tours/${tour.slug}`} className="btn btn-md btn-primary-ii btn-submit w-full">
              Back To Tours
            </Link>
          </div>

          <p className="copyright-text">© Lineage Journey {currentYear}.</p>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default BookingSuccess;
