import React from 'react';

import PayableAmount from './PayableAmount';
import { PaymentPlans } from '../../../../constants/tour';

const TotalTravellersAndAmount = ({ travellerQuantity, totalTourAmount, paymentPlan = PaymentPlans.FULL }) => {
  return (
    <div style={{ margin: '2.1875rem 0', display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <span className="text-traveller-quantity">Total number of Travellers:</span>
        <span className="text-main" style={{ fontWeight: 700, paddingLeft: '6px' }}>
          {travellerQuantity}
        </span>
      </div>

      <PayableAmount paymentPlan={paymentPlan} totalAmount={totalTourAmount} />
    </div>
  );
};

export default TotalTravellersAndAmount;
