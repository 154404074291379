import { Box } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import CountryList from 'country-list-with-dial-code-and-flag';

import Grid from '../../../Grid';
import TourInfo from './TourInfo';
import Extensions from './Extensions';
import OccupancyType from './OccupancyType';
import TourErrorModal from '../TourErrorModal';
import FinalPayableAmount from './FinalPayableAmount';
import TotalTravellersAndAmount from './TotalTravellersAndAmount';
import { useTourBooking } from '../../../../context/tourBookingContext';

const PackageDetails = ({ goToNextView, goToPreviousView, tour, isPrivateTour }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isAlreadyBooked, setIsAlreadyBooked] = useState(false);
  const { setTourBooking, tourBooking, togglePreExtension, togglePostExtension, setOccupancyType } = useTourBooking();

  useEffect(() => {
    // A tour may only have one occupancy type. We are setting the default type with whichever occupancy type is available.
    if (tour?.pricePerPerson?.singleOccupancy) {
      setOccupancyType('single');
    } else {
      setOccupancyType('double');
    }

    setTourBooking({ ...tourBooking, tourSlug: tour.slug });
  }, [tour]);

  const onFormSubmitSuccess = () => {
    goToNextView();
  };

  const onFormSubmitFail = (err) => {
    setIsErrorModalOpen(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { dialCode } = CountryList.findOneByCountryCode(tourBooking.countryCode);

    setIsSubmitting(true);
    const postData = {
      ...tourBooking,
      phone: `(${dialCode}) ${tourBooking.phone}`,
      country: CountryList.findOneByCountryCode(tourBooking.countryCode).name,
    };
    if (isPrivateTour) {
      postData['isPrivateTour'] = isPrivateTour;
      postData['mainTour'] = tourBooking.postExtension;
      postData['postExtension'] = false;
    }

    fetch(`${process.env.GATSBY_LINEAGE_BACKEND_URL}api/tour-bookings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then((resp) => resp.json())
      .then((body) => {
        if (body.success) {
          onFormSubmitSuccess(body.data);
        } else {
          body.message === 'You have already booked this tour' && setIsAlreadyBooked(true);
          onFormSubmitFail();
        }
      })
      .catch((err) => {
        console.error(err.message);
        onFormSubmitFail();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const pricePerPerson = useMemo(() => {
    if (tourBooking.occupancyType === 'single') {
      return {
        tour: tour.pricePerPerson.singleOccupancy,
        preExtension: tour.pricePerPerson.preExtensionSingleOccupancy,
        postExtension: tour.pricePerPerson.postExtensionSingleOccupancy,
      };
    }
    return {
      tour: tour.pricePerPerson.doubleOccupancy,
      preExtension: tour.pricePerPerson.preExtensionDoubleOccupancy,
      postExtension: tour.pricePerPerson.postExtensionDoubleOccupancy,
    };
  }, [tourBooking.occupancyType]);

  const travellerQuantity = tourBooking.additionalMembers.length + 1; // +1 for the main traveller
  const totalTourAmount = travellerQuantity * pricePerPerson.tour;

  const preExtension = {
    pricePerPerson: pricePerPerson.preExtension,
    toggle: togglePreExtension,
    isSelected: tourBooking.preExtension,
    totalAmount: tourBooking.preExtension ? travellerQuantity * pricePerPerson.preExtension : 0,
  };

  const postExtension = {
    pricePerPerson: pricePerPerson.postExtension,
    toggle: togglePostExtension,
    isSelected: tourBooking.postExtension,
    totalAmount: tourBooking.postExtension ? travellerQuantity * pricePerPerson.postExtension : 0,
  };

  const hasExtensions = tour?.hasPreExtension || tour?.hasPostExtension;
  const bothBothOccupancyTypes = tour?.pricePerPerson?.singleOccupancy && tour?.pricePerPerson?.doubleOccupancy;

  const tourAlreadyBookedTitle = 'Tour Already Booked';
  const tourAlreadyBookedMessage = 'You have already booked this tour. Please contact us for any changes.';

  return (
    <>
      <Grid>
        <Grid.Col md="2 / span 10" xs="1 / span 2">
          <form onSubmit={handleSubmit} className="package-details">
            <TourInfo tour={tour} />

            {bothBothOccupancyTypes && (
              <OccupancyType occupancyType={tourBooking.occupancyType} setOccupancyType={setOccupancyType} />
            )}

            <TotalTravellersAndAmount travellerQuantity={travellerQuantity} totalTourAmount={totalTourAmount} />

            {hasExtensions && (
              <Extensions preExtension={preExtension} postExtension={postExtension} isPrivateTour={isPrivateTour} />
            )}

            <FinalPayableAmount
              totalTourAmount={totalTourAmount}
              preExtension={preExtension}
              postExtension={postExtension}
              travellerQuantity={travellerQuantity}
            />

            <div className="btn-group">
              <Box
                sx={{
                  width: {
                    xs: '100%',
                    md: '17.625rem',
                  },
                }}>
                <input
                  type="button"
                  value="Previous"
                  className="btn-md btn-outline w-full"
                  onClick={goToPreviousView}
                />
              </Box>
              <Box
                sx={{
                  width: {
                    xs: '100%',
                    md: '17.625rem',
                  },
                }}>
                {isSubmitting ? (
                  <button
                    type="button"
                    className="btn-md btn-outline btn-loading w-full"
                    disabled
                    style={{
                      cursor: 'not-allowed',
                    }}>
                    <span className="spinner"></span> Submitting
                  </button>
                ) : (
                  <button type="submit" className="btn btn-md btn-primary-ii btn-submit w-full">
                    Book
                  </button>
                )}
              </Box>
            </div>
          </form>
        </Grid.Col>
      </Grid>
      <TourErrorModal
        open={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        title={isAlreadyBooked && tourAlreadyBookedTitle}
        body={isAlreadyBooked && tourAlreadyBookedMessage}
      />
    </>
  );
};

export default PackageDetails;
