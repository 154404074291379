import React, { useState } from 'react';
import { Popover, Combobox } from '@headlessui/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CountryList from 'country-list-with-dial-code-and-flag';

import { isBrowser } from '../../../utils/isBrowser';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import SearchIcon from '../../../images/icon-search-faded.svg';

const CountrySelector = ({ countryCode, setCountryCode }) => {
  const [query, setQuery] = useState('');

  if (!isBrowser()) return null; // avoid rendering on server side

  const filteredCountries =
    query === ''
      ? CountryList.getAll()
      : CountryList.getAll().filter((country) => {
          return country.name.toLowerCase().startsWith(query.toLowerCase());
        });

  return (
    <Popover
      style={{
        position: 'relative',
      }}
    >
      {({ open }) => (
        <>
          <Popover.Button
            className="select"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '18px',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              {CountryList.findOneByCountryCode(countryCode).name}
            </div>

            <ExpandMoreIcon
              fontSize="small"
              style={{ transform: open ? 'rotate(180deg)' : 'rotate(0)', transition: 'transform 0.3s ease-in-out' }}
            />
          </Popover.Button>

          <Popover.Panel
            style={{
              position: 'absolute',
              width: '100%',
              zIndex: 10,
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
            }}
          >
            {({ close }) => (
              <Combobox
                value={countryCode}
                onChange={(value) => {
                  setCountryCode(value);
                  close();
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                    backgroundColor: '#fff',
                    width: '100%',
                  }}
                >
                  <Combobox.Input
                    value={query}
                    onChange={(event) => setQuery(event.target.value)}
                    style={{
                      backgroundImage: `url('${SearchIcon}')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '10px center',
                      paddingLeft: '48px',
                      backgroundColor: '#fff',
                      width: '100%',
                    }}
                    autoFocus
                    placeholder="Search"
                  />
                </div>
                <Combobox.Options
                  static
                  style={{
                    maxHeight: '18.75rem',
                    overflowY: 'scroll',
                    backgroundColor: '#FFF',
                  }}
                >
                  {filteredCountries.map((country, index) => (
                    <Combobox.Option key={index} value={country.code}>
                      {({ selected, active }) => (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'start',
                            padding: '14px 16px',
                            backgroundColor: `${selected ? '#FECB04' : '#FFF'}`,
                            cursor: 'pointer',
                          }}
                        >
                          <span
                            className={`fi fi-${country.code.toLowerCase()}`}
                            style={{
                              flexShrink: 0,
                              marginRight: '16px',
                              paddingTop: '4px',
                            }}
                          ></span>
                          <span>{country.name}</span>
                        </div>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </Combobox>
            )}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default CountrySelector;
