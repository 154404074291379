import qs from 'qs';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import BodyClassName from 'react-body-classname';

import Stepper from '../../components/Stepper';
import { scrollToTop } from '../../utils/scrollToTop';
import { TourBookingProvider } from '../../context/tourBookingContext';
import PackageDetails from '../../components/Tour/TourBooking/PackageDetails';
import BookingSuccess from '../../components/Tour/TourBooking/BookingSuccess';
import PersonalDetails from '../../components/Tour/TourBooking/PersonalDetails';
import DefaultLayoutComponent from '../../modules/layouts/DefaultLayoutComponent';

const ViewsEnum = {
  PERSONAL_DETAILS: 'Personal Details',
  PACKAGE_DETAILS: 'Package Details',
  BOOKING_COMPLETE: 'Booking Complete',
};

const TourBooking = ({ data, pageContext }) => {
  const {
    tour: { nodes },
  } = data;

  const { isPrivateTour } = pageContext;

  const [tourQuery] = nodes;

  const title = tourQuery.data.tourTitle.text;
  const bookingImage = tourQuery.data.bookingImage;

  const {
    metaDescription,
    pricePerPersonSingleOccupancy,
    pricePerPersonDoubleOccupancy,
    preExtensionPricePerPersonSingleOccupancy,
    preExtensionPricePerPersonDoubleOccupancy,
    postExtensionPricePerPersonSingleOccupancy,
    postExtensionPricePerPersonDoubleOccupancy,
    thumbnailImage,
  } = tourQuery.data;

  const tourData = {
    title,
    slug: tourQuery.uid,
    thumbnail: {
      url: thumbnailImage.fluid.src,
      alt: thumbnailImage.alt,
    },
    bookingImage: {
      url: bookingImage.fluid.src,
      alt: bookingImage.alt,
    },
    pricePerPerson: {
      singleOccupancy: pricePerPersonSingleOccupancy,
      doubleOccupancy: pricePerPersonDoubleOccupancy,
      preExtensionSingleOccupancy: preExtensionPricePerPersonSingleOccupancy,
      preExtensionDoubleOccupancy: preExtensionPricePerPersonDoubleOccupancy,
      postExtensionSingleOccupancy: postExtensionPricePerPersonSingleOccupancy,
      postExtensionDoubleOccupancy: postExtensionPricePerPersonDoubleOccupancy,
    },
    hasPreExtension: preExtensionPricePerPersonSingleOccupancy || preExtensionPricePerPersonDoubleOccupancy,
    hasPostExtension: postExtensionPricePerPersonSingleOccupancy || postExtensionPricePerPersonDoubleOccupancy,
  };

  const location = useLocation();
  const queryString = qs.parse(location.search.substring(1));
  const isBookingSuccess = queryString['booking-success'] === 'true';

  const [currentView, setCurrentView] = useState(isBookingSuccess ? ViewsEnum.ALL_DONE : ViewsEnum.PERSONAL_DETAILS);

  const goToNextView = () => {
    scrollToTop();
    const nextView = Object.values(ViewsEnum)[Object.values(ViewsEnum).indexOf(currentView) + 1];
    setCurrentView(nextView);
  };

  const goToPreviousView = () => {
    scrollToTop();
    const previousView = Object.values(ViewsEnum)[Object.values(ViewsEnum).indexOf(currentView) - 1];
    setCurrentView(previousView);
  };

  const steps = Object.values(ViewsEnum);

  return (
    <TourBookingProvider>
      <BodyClassName className="body-dark">
        <DefaultLayoutComponent title={title} description={metaDescription}>
          <div className="site-main">
            <div
              className={`wrapper ${currentView === ViewsEnum.PACKAGE_DETAILS ? 'wrapper-md' : 'wrapper-xl'}  pd-x-md`}>
              <Box className="bg-light tourBooking">
                <h1>Booking form</h1>

                {/* Horizontal Stepper */}
                <Stepper currentStepIndex={steps.indexOf(currentView)} steps={steps} />

                <div className="tour-booking-body">
                  {currentView === ViewsEnum.PERSONAL_DETAILS ? (
                    <PersonalDetails goToNextView={goToNextView} bookingImage={bookingImage} />
                  ) : currentView === ViewsEnum.PACKAGE_DETAILS ? (
                    <PackageDetails
                      goToNextView={goToNextView}
                      tour={tourData}
                      goToPreviousView={goToPreviousView}
                      isPrivateTour={isPrivateTour}
                    />
                  ) : (
                    <BookingSuccess tour={tourData} />
                  )}
                </div>
              </Box>
            </div>
          </div>
        </DefaultLayoutComponent>
      </BodyClassName>
    </TourBookingProvider>
  );
};

export default TourBooking;

export const query = graphql`
  query TourBookingPage($uid: String!) {
    tour: allPrismicTour(filter: { uid: { eq: $uid } }) {
      nodes {
        data {
          bookingImage: booking_image {
            url
            alt
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
            }
          }
          thumbnailImage: thumbnail_image {
            url
            alt
            fluid(maxWidth: 120) {
              ...GatsbyPrismicImageFluid
            }
          }
          tourTitle: tour_title {
            text
          }
          metaDescription: meta_description
          pricePerPersonSingleOccupancy: price_per_person_single_occupancy
          pricePerPersonDoubleOccupancy: price_per_person_double_occupancy
          preExtensionPricePerPersonSingleOccupancy: pre_extension_price_per_person_single_occupancy
          preExtensionPricePerPersonDoubleOccupancy: pre_extension_price_per_person_double_occupancy
          postExtensionPricePerPersonSingleOccupancy: post_extension_price_per_person_single_occupancy
          postExtensionPricePerPersonDoubleOccupancy: post_extension_price_per_person_double_occupancy
        }
        uid
      }
    }
  }
`;
