import React, { useContext } from 'react';
import { Box } from '@mui/material';

import { PaymentPlans } from '../../../../constants/tour';
import { AppContext } from '../../../../apollo/wrap-root-element';
import { currencySymbolMap } from '../../../Header/currencyswitcher/CurrencySwitcher';

const PayableAmount = ({ paymentPlan = PaymentPlans.FULL, totalAmount }) => {
  const { currency, currenciesRate } = useContext(AppContext);
  const [selectedRate] = !!currenciesRate?.length && currenciesRate.filter((elm) => elm.currency === currency);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: {
            xs: 'flex-end',
            md: 'center',
          },
          gap: {
            xs: '0',
            md: '4px',
          },
        }}>
        <Box
          sx={{
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            justifyContent: 'end',
          }}>
          <span className="text-sub">Total:</span>

          <span className="text-main" style={{ fontWeight: 500 }}>
            {paymentPlan === PaymentPlans.FULL
              ? (currencySymbolMap[currency] || '$') + parseFloat((selectedRate.rate * totalAmount).toFixed(2))
              : (currencySymbolMap[currency] || '$') + parseFloat((selectedRate.rate * 0.2 * totalAmount).toFixed(2))}
          </span>
        </Box>
        <span
          className="text-sub"
          style={{
            textAlign: 'right',
          }}>
          {paymentPlan === PaymentPlans.PARTIAL && ' (20% of payable amount)'}{' '}
        </span>
      </Box>
      <div className="text-right text-sub">
        {paymentPlan === PaymentPlans.PARTIAL && (
          <>
            {(currencySymbolMap[currency] || '$') + parseFloat((selectedRate.rate * totalAmount).toFixed(2))} payable in
            total
          </>
        )}
      </div>
    </div>
  );
};

export default PayableAmount;
