import React, { useState, useContext, createContext } from 'react';

const INITIAL_STATE = {
  tourSlug: '',
  name: '',
  email: '',
  countryCode: 'US',
  phone: '',
  ageRange: '',
  additionalMembers: [],
  preExtension: false,
  postExtension: false,
  occupancyType: 'single',
};

const TourBookingContext = createContext();

export const TourBookingProvider = ({ children }) => {
  const [tourBooking, setTourBooking] = useState(INITIAL_STATE);

  const clearAllMembers = () => setTourBooking({ ...tourBooking, additionalMembers: [] });

  const addMember = (member) =>
    setTourBooking({ ...tourBooking, additionalMembers: [...tourBooking.additionalMembers, member] });

  const removeMember = (index) => {
    const newMembers = [...tourBooking.additionalMembers];
    newMembers.splice(index, 1);
    setTourBooking({ ...tourBooking, additionalMembers: newMembers });
  };

  const removeMembers = (membersIndex) => {
    const newMembers = [...tourBooking.additionalMembers];

    // Sort the indexes in descending order
    membersIndex.sort((a, b) => b - a);

    membersIndex.forEach((index) => {
      if (index < newMembers.length) {
        newMembers.splice(index, 1);
      }
    });

    setTourBooking({ ...tourBooking, additionalMembers: newMembers });
  };

  const togglePreExtension = () => setTourBooking({ ...tourBooking, preExtension: !tourBooking.preExtension });
  const togglePostExtension = () => setTourBooking({ ...tourBooking, postExtension: !tourBooking.postExtension });

  const setOccupancyType = (occupancyType) => setTourBooking({ ...tourBooking, occupancyType });

  const resetTourBooking = () => setTourBooking(INITIAL_STATE);

  return (
    <TourBookingContext.Provider
      value={{
        tourBooking,
        setTourBooking,
        clearAllMembers,
        addMember,
        removeMember,
        removeMembers,
        resetTourBooking,
        togglePostExtension,
        togglePreExtension,
        setOccupancyType,
      }}
    >
      {children}
    </TourBookingContext.Provider>
  );
};

export const useTourBooking = () => useContext(TourBookingContext);
