import React from 'react';

import { OccupancyTypes } from '../../../../constants/tour';

const OccupancyType = ({ occupancyType, setOccupancyType }) => {
  const handleOccupancyChange = (event) => {
    setOccupancyType(event.target.value);
  };

  return (
    <div
      style={{
        marginTop: '2rem',
      }}
    >
      <h3 className="radio-heading">Accomodation Option</h3>
      <div className="radio-group">
        <input
          type="radio"
          id="single-occupancy"
          name="occupancy-type"
          value="single"
          onChange={handleOccupancyChange}
          checked={occupancyType === OccupancyTypes.SINGLE}
        />
        <label
          htmlFor="single-occupancy"
          className="payment-plan-label"
          style={{
            textTransform: 'capitalize',
          }}
        >
          Single Occupancy
        </label>
      </div>

      <div
        className="radio-group"
        style={{
          marginTop: '1rem',
        }}
      >
        <input
          type="radio"
          id="double-occupancy"
          name="occupancy-type"
          value="double"
          onChange={handleOccupancyChange}
          checked={occupancyType === OccupancyTypes.DOUBLE}
        />
        <label
          htmlFor="double-occupancy"
          className="payment-plan-label"
          style={{
            textTransform: 'capitalize',
          }}
        >
          Double Occupancy
        </label>
      </div>
    </div>
  );
};

export default OccupancyType;
