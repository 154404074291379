import React, { useContext } from 'react';

import PayableAmount from './PayableAmount';
import Checkbox from '../../../Form/CheckBox';
import { PaymentPlans } from '../../../../constants/tour';
import { AppContext } from '../../../../apollo/wrap-root-element';
import { currencySymbolMap } from '../../../Header/currencyswitcher/CurrencySwitcher';

const PreExtension = ({ preExtension, paymentPlan = PaymentPlans.FULL, isPrivateTour }) => {
  const { currency, currenciesRate } = useContext(AppContext);
  const [selectedRate] = !!currenciesRate?.length && currenciesRate.filter((elm) => elm.currency === currency);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
      <div>
        <div style={{ display: 'flex', alignContent: 'center', gap: '12px' }}>
          <Checkbox
            value="pre-extension"
            onChange={preExtension.toggle}
            id="pre-extension"
            checked={preExtension.isSelected}
          />
          <label
            style={{
              fontWeight: '600',
            }}
            htmlFor="pre-extension">
            {isPrivateTour ? 'Pre-Extension Tour (June 2-4 2025)' : 'Pre-extension (Optional)'}
          </label>
        </div>
        <div className="text-sub" style={{ marginTop: '8px' }}>
          Price:
          {(currencySymbolMap[currency] || '$') +
            parseFloat((selectedRate.rate * preExtension.pricePerPerson).toFixed(2))}
          /person
        </div>
      </div>

      <PayableAmount paymentPlan={paymentPlan} totalAmount={preExtension.totalAmount} />
    </div>
  );
};

const PostExtension = ({ postExtension, paymentPlan = PaymentPlans.FULL, isPrivateTour }) => {
  const { currency, currenciesRate } = useContext(AppContext);
  const [selectedRate] = !!currenciesRate?.length && currenciesRate.filter((elm) => elm.currency === currency);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
      <div>
        <div style={{ display: 'flex', alignContent: 'center', gap: '12px' }}>
          <Checkbox
            value="post-extension"
            onChange={postExtension.toggle}
            id="post-extension"
            checked={postExtension.isSelected}
          />
          <label
            style={{
              fontWeight: '600',
            }}
            htmlFor="post-extension">
            {isPrivateTour ? 'Main Tour (June 4-13 2025)' : 'Post-extension'}
          </label>
        </div>
        <div className="text-sub" style={{ marginTop: '8px' }}>
          Price:
          {(currencySymbolMap[currency] || '$') +
            parseFloat((selectedRate.rate * postExtension.pricePerPerson).toFixed(2))}
          /person
        </div>
      </div>

      <PayableAmount paymentPlan={paymentPlan} totalAmount={postExtension.totalAmount} />
    </div>
  );
};

const Extensions = ({ preExtension, postExtension, paymentPlan = PaymentPlans.FULL, isPrivateTour }) => {
  return (
    <div style={{ paddingBottom: '32px' }}>
      <div className="uppercase" style={{ fontWeight: '600' }}>
        Add Tour Extension
      </div>

      {preExtension.pricePerPerson && (
        <PreExtension preExtension={preExtension} paymentPlan={paymentPlan} isPrivateTour={isPrivateTour} />
      )}
      {!isPrivateTour && postExtension.pricePerPerson && (
        <PostExtension postExtension={postExtension} paymentPlan={paymentPlan} isPrivateTour={isPrivateTour} />
      )}
    </div>
  );
};

export default Extensions;
