import React from 'react';

const Stepper = ({ steps, currentStepIndex }) => {
  const getStepCircleClass = (index) => {
    if (currentStepIndex === index) {
      return 'c-stepper__item current';
    } else if (currentStepIndex < index) {
      return 'c-stepper__item';
    } else {
      return 'c-stepper__item completed';
    }
  };

  return (
    <div className="stepper-wrapper">
      <ol className="c-stepper">
        {steps.map((step, index) => (
          <li key={index} className={getStepCircleClass(index)}>
            <p className="c-stepper__desc">{step}</p>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Stepper;
